// 测试环境
const isTest = process.env.VUE_APP_EVN === 'development'

// 火箭游戏域名
export const rocketGameOrigin = `${window.origin}`
// 坦克游戏域名
export const tankGameOrigin = isTest ? 'https://beautiful-choux-bb6a89.netlify.app' : `https://tank.ordz.games`
// rush游戏域名
export const rushGameOrigin = isTest ? 'https://clever-bhabha-c96f33.netlify.app' : `https://rush.ordz.games`
// heroes游戏域名
export const heroesGameOrigin = isTest ? 'https://classy-wisp-dba456.netlify.app' : `https://ordz-heroes.ordz.games`

// 接口
export const apiUrl = isTest ? 'https://staging-api.bitcoinnftz.xyz' : `https://api.ordz.games`
// export const apiUrl = isTest ? 'https://staging-api.bitcoinnftz.xyz' : `https://staging-api.bitcoinnftz.xyz`
// 官网
export const officialWebsiteUrl = isTest ? 'https://staging.ordz.games' : 'https://www.ordz.games'

// heroes
export const herosApiLink = isTest ? 'https://staging-hero-api.ordz.games' : 'https://hero-api.ordz.games'
export const herosImgOrigin = `https://ordz-pfp-prod.oss-cn-hongkong.aliyuncs.com`

// 测试环境play地址 https://staging-play-ordz-games.netlify.app




// 默认的nft数据
let _defaultHeroesMapping = {
  1: {
    Background: 'default-background-1',
    Body: 'default-body-1',
  },
  // 1: {
  //   Background: 'default-background-1',
  //   Body: 'default-body-1',
  //   // Body: 'defate-body-1',
  //   // Face: '',
  //   // Clothes: '',
  //   // Head: '',
  //   // Extra: '',
  // },
}
Object.keys(_defaultHeroesMapping).forEach(key => {
  _defaultHeroesMapping[key] = {
    ..._defaultHeroesMapping[key],
    inscription: key,
    isDefault: true,
    roleImg: _defaultHeroesMapping[key] || {}
  }
})
export const defaultHeroesMapping = _defaultHeroesMapping

// 默认的nft列表
export const defaultHeroesList = Object.keys(_defaultHeroesMapping).map(key => {
  return _defaultHeroesMapping[key]
})

